/* scroll bar settings */

/* width */
::-webkit-scrollbar, body::-webkit-scrollbar {width: 8px;height: 8px;border-radius: 10px;}
  
/* Track */
::-webkit-scrollbar-track, body::-webkit-scrollbar-track {background: lightgray;border-radius: 25px;}

/* Handle */
::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {background: #007bff; border-radius: 25px;}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {background: #555;}


  
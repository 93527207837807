// This file contains only variables, mixins, etc.
// It should NOT contain any final CSS code that would be included into the final bundle
$bt-primary-color:       #0058CB;
$bt-secondary-color:     #106EBE;
$bt-secondary-light:     #106EBE;
$bt-border-color:        #D2D2D2;
$body-bg-color:           #EEEEEE;
$table-over-color:        #cce5ff;
$table-first-row-bg:      #70A6FF;
$table-pattern:           #f5f5f5;
$bt-border-color-light:  #ebebeb;
$table-filter-section :   #F2F2F2;
$text-color:              #000000;
$button-bg-color:         #007bff;
$button-over-bg-color:    #0271e8;

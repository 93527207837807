// @import url('sass/variables/_variables.scss');
    .mercButton{
        /* float: right; */
    /* padding: 2px; */
  
    margin-left: 16%;
    border: none;
    color: white;
    border-radius: 5px;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #0079e4;
    width: 30%;
    cursor: pointer;
}
.taxExeButton{
    margin-left: 3%;
    border: none;
    color: white;
    width: 18% !important;
    border-radius: 5px;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #0079e4;
    cursor: pointer;
}

// MAterial Button

.materialButton {
  width: max-content;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    // margin: 0 8px;
    border: none;
    border-radius: 2px;
    padding: 0 16px;
    min-width: 64px;
    height: 32px;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    color: #fff;
    background-color: #0058CB;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    font-family: "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.2s;
  }
  
  .materialButton:hover,
  .materialButton:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  
  .materialButton:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  
  .materialButton:disabled {
    color: rgba(0, 0, 0, 0.38);
    background-color: rgba(0, 0, 0, 0.12);
    box-shadow: none;
    cursor: initial;
  }
  
  .materialButton::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: currentColor;
    opacity: 0;
    transition: opacity 0.2s;
  }
  
  .materialButton:hover::before {
    opacity: 0.12;
  }
  
  .materialButton:focus::before {
    opacity: 0.2;
  }
  
  .materialButton:active::before {
    opacity: 0.32;
  }
  
  .materialButton:disabled::before {
    opacity: 0;
  }
  
  .materialButton::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 18px;
    border-radius: 50%;
    padding: 50%;
    width: 32px;
    height: 32px;
    background-color: currentColor;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1) ;
    transition: opacity 1s, transform 0.5s;
  }
  
  .materialButton:active::after {
    opacity: 0.4;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0s;
  }
  
  .materialButton:disabled::after {
    opacity: 0;
  }
  .bt-btn-primay{color:#fff;text-transform: uppercase;font-size: 14px;font-family: 'Segoe UI';padding:10px 30px;background-color:#3B86FF;text-align: center; }
  .bt-btn-primay:hover{background-color:#1E6FE5; }
  .bt-btn-primay:focus{background-color:#1E6FE5; }
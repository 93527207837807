$bt-secondary-color:     #3757C5;
$button-bg-color:         #007bff;
//checkbox for table


/* The container */
.checkcontainerTable {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    margin-left: 40%;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkcontainerTable input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #0079e4;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.checkcontainerTable:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkcontainerTable input:checked ~ .checkmark {
    background-color: #0079e4;
    border-radius: 5px;
    border: 1px solid #0079e4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkcontainerTable input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkcontainerTable .checkmark:after {
    left: 6px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


///////////////////////////////////////////////
// Material Checkbox
.pure-material-checkbox {
    position: relative;
    display: inline-block;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    line-height: 18px;
  }
  
  .pure-material-checkbox > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    position: absolute;
    z-index: -1;
    left: -15px;
    top: -15px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    background-color: rgba(0, 0, 0, 0.42);
    outline: none;
    opacity: 0;
    transform: scale(1);
    -ms-transform: scale(0); /* Graceful degradation for IE */
    transition: opacity 0.5s, transform 0.5s;
  }
  
  .pure-material-checkbox > input:checked {
    background-color: #2196f3;
  }
  
  .pure-material-checkbox:active > input {
    opacity: 1;
    transform: scale(0);
    transition: opacity 0s, transform 0s;
  }
  
  .pure-material-checkbox > input:disabled {
    opacity: 0;
  }
  
  .pure-material-checkbox > input:disabled + span {
    color: rgba(0, 0, 0, 0.38);
    cursor: initial;
  }
  
  .pure-material-checkbox > span::before {
    content: "";
    display: inline-block;
    margin-right: 15px;
    border: solid 1px#ced4da;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    vertical-align: -4px;
    transition: border-color 0.5s, background-color 0.5s;
  }
  
  .pure-material-checkbox > input:checked + span::before {
    border-color: $button-bg-color;
    background-color: $button-bg-color;
  }
  
  .pure-material-checkbox > input:active + span::before {
    border-color: #2196f3;
  }
  
  .pure-material-checkbox > input:checked:active + span::before {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.42);
  }
  
  .pure-material-checkbox > input:disabled + span::before {
    border-color: rgba(0, 0, 0, 0.26); 
    background-color: rgba(0, 0, 0, 0.26);

  }
  
  .pure-material-checkbox > input:checked:disabled + span::before {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.26);
  }
  
  .pure-material-checkbox > span::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 1px;
    left: 0px;
    width: 6px;
    height: 11px;
    border: solid 2px transparent;
    border-left: none;
    border-top: none;
    transform: translate(5.5px, 1px) rotate(45deg);
    -ms-transform: translate(5.5px, 2px) rotate(45deg);
  }
  
  .pure-material-checkbox > input:checked + span::after {
    border-color: #fff;
  }
  
  .pure-material-checkbox.default-check  > input:disabled + span::before {
    border-color: white;
    background-color: white;
  }

  ////////////////////////////Radio button Material////////////////////////////////@at-root

  

$md-radio-checked-color: rgb(51, 122, 183);
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px; 
$md-radio-ripple-size: 15px;

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }
  50% { 
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.md-radio {
    margin: 0 0;
    
    &.md-radio-inline {
        display: inline-block;
    }

    input[type="radio"] {
        display: none;
        &:checked + label:before {
            border-color: $md-radio-checked-color;
            animation: ripple 0.2s linear forwards;   
        }
        &:checked + label:after {
            transform: scale(1);
        }
    }
    
    label {
        display: inline-block;
        height:$md-radio-size;
        position: relative;
        padding: 0 ($md-radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        &:before, &:after {
            position: absolute;            
            content: '';  
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }
        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 2px solid $md-radio-border-color;
        }
        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 2;
            left: $md-radio-size / 2 - $md-radio-checked-size / 2;
            width:$md-radio-checked-size;
            height:$md-radio-checked-size;
            transform: scale(0);
            background:$md-radio-checked-color;
        }
    }
}



/* You can add global styles to this file, and also import other style files */
body{
  font-size: 14px !important;
}

.alert-danger {
  color: #b31818;
  width: 100%;
  font-size: 11px;
  transition: 0.3s;
}
.alert {
  position: relative;
  margin-bottom: 0;

}

.lblr{
  color: red;
 }

 @media screen and (min-width: 1200px) {
  .container {
    max-width: 92% !important;
  }
}


.reservationCls {
background-color: skyblue !important;
 span{
  color: whitesmoke !important;
 }
 span:hover{
  color: black !important;
 }
}

#documenteditor_title_contentEditor {

  width: 56px !important;
    /* margin: 0; */
    display: inline-block !important;
    margin-top: 8px !important;;
}

.txtRequired {
border: 1px solid rgb(218, 6, 6) !important;
-webkit-background-clip: padding-box; /* for Safari */
background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
-webkit-animation: underBlink 1s infinite;
-moz-animation:    underBlink 1s infinite;
-o-animation:      underBlink 1s infinite;
animation:         underBlink 1s infinite;
}

@-webkit-keyframes underBlink {
0%   { border: 1px solid rgba(243, 167, 167, 0.2); }
100% { border: 1px solid rgb(211, 9, 9); }
}
@-moz-keyframes underBlink {
0%   { border: 1px solid rgba(243, 167, 167, 0.2); }
100% { border: 1px solid rgb(211, 9, 9); }
}
@-o-keyframes underBlink {
0%   { border: 1px solid rgba(243, 167, 167, 0.2); }
100% { border: 1px solid rgb(211, 9, 9); }
}
@keyframes underBlink {
0%   { border: 1px solid rgba(243, 167, 167, 0.2); }
100% { border: 1px solid rgb(211, 9, 9); }
}

.highlight {
color: #3757c5 !important;
text-decoration: underline !important;
font-weight: 600;
}

.viewRightCls{
float: left;
width: 100%;
background-color: #d1ecf1;
color: #0c5460;
font-size: 14px;
border-radius: 2px;
padding: 0.75rem 1.25rem;;
text-align: center;
}

.geo_location_alert_box{
width: 400px;
}

@media print {
app-print-ledger {
  display: block;
}
@page {
  size: A4 landscape;}
}
.e-pdfviewer .e-pv-toolbar.e-toolbar:not(.e-rtl) .e-toolbar-items .e-toolbar-item:last-child, .e-pdfviewer .e-pv-annotation-toolbar.e-toolbar:not(.e-rtl) .e-toolbar-items .e-toolbar-item:last-child, .e-pdfviewer .e-pv-formdesigner-toolbar.e-toolbar:not(.e-rtl) .e-toolbar-items .e-toolbar-item:last-child {
display: none !important;
}

.btn-primary{
  background-color: #106EBE !important;
  border-color: #106EBE !important;
  color: white !important;
  
  font-size: 14px;

}

.btn-primary:hover{
  background-color: #2493F2 !important;
  border-color: #2493F2 !important;
  color: white !important;
}

.btnWidth{
  width:70px;
}

.swal2-confirm{
  @extend .btn-primary;
  min-width: 100px;
  text-shadow: none;
 

}

.swal2-cancel{
  min-width: 100px;
}

.colDelete{
  border: none;
  background: none;
}

.filterheader{
  background-color: #f0f0f0 !important;
  .filterinput{
    border-radius: 5px;
  }
}


/*Ravish Bhat 12/12/2017 ++++*/


.chatheaderback{
    /*background-color: #0079e4;*/
    background-color:white;
}


     /** style of textmsg start **/



/** start of message window style sheet **/
#draggablemessage { width: 300px; height: 0px; padding: 0;z-index:565;top: -20px;    left: 350px;position: absolute; }

.col-md-2, .col-md-10{
    padding:0;
}
.panel{
    margin-bottom: 0px;
}
.chat-window{
    bottom:0;
    position:absolute;
    float:right;
    margin-left:10px;
        top: 85px;
    left: 315px;
}
.chat-window > div > .panel{
    // border-radius: 5px;
    border: 5px solid white;
    background-color: white;
    box-shadow: 0px 1px 4px 1px #484646;

}
.icon_minim{
    padding:2px 10px;
}
.msg_container_base{
  // After discussing with Koshy looks like this image is not used anymore
  // background-image:url("../images/chtback1.png");

      /*background-image:url("../images/img1.jpg");*/
  /*background: #e5e5e5;*/
  margin: 0;
  padding: 0 10px 10px;
  /*max-height:255px;*/
  overflow-x:hidden;
  height:285px;
}
.top-bar {
  background: #666;
  color: white;
  padding: 10px;
  position: relative;
  overflow: hidden;
}
.msg_receive{
    padding-left:0;
    margin-left:0;
}
.msg_sent{
    padding-bottom:20px !important;
    margin-right:0;
}
.messages {
  background: #f5b3b3;
  padding: 12px;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width:100%;
  margin-top: 5px;
}
.messages > p {
    font-size: 13px;
    margin: 0 0 0.2rem 0;
  }
.messages > time {
    font-size: 11px;
    color: #ccc;
}
.msg_container {
    padding: 10px;
    overflow: hidden;
    display: flex;
}
/*img {
    display: block;
    width: 100%;
}*/
.avatar {
    position: relative;
}
.messagescontainer > .avatar:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border: 5px solid #FFF;
    border-left-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
}

.messagescontainersent {
  justify-content: flex-end;
  align-items: flex-end;
}
.messagescontainersent > .avatar:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 5px solid white;
    border-right-color: transparent;
    border-top-color: transparent;
    box-shadow: 1px 1px 2px rgba(black, 0.2);
}

.msg_sent > time{
    float: right;
}

.msg_container_base::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.msg_container_base::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

.msg_container_base::-webkit-scrollbar-thumb
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

.btn-group.dropup{
    position:fixed;
    left:0px;
    bottom:0;
}
  .preview{
        color:chocolate;
    }
    .tenantname{
    color: #110286;
    font-family: helvetica;
    font-size: 15px;
    }
   .tenantname:hover{
        /*font-size: 18px;*/
        font-weight:700;
   }
    #tenantdesc{

        background-color:white;
        height:45px;
        color:black;
        overflow:hidden;
        border:none;
            border-bottom: 2px solid darkgray;


    }
    #tenantdesc h5 {
        color: crimson;
        font-weight:bold;
        font-size:14px;
    }
     #tenantdesc h6 {

        font-weight:bold;
    }
     .panel-body{
         padding:4px;

     }
     #profilepic{
        height: 50px;
        width: 55px;
        margin-top: -37px;
        margin-left: 224px;
     }
     #personal{
         line-height:5px;
     }

     #sendtextarea{
             width: 200px;
    height: 70px;
    padding: 5px;
    margin-left: 14px;
    margin-top: 10px;
    background-color: #ffffffd9;
        font-family: sans-serif;
        border:none;

     }

     /*---*CHAT*-----*/
#chat .panel-heading{
    background-image: url(http://www.caixa.gov.br/Style%20Library/images/bg_destaqueInternas.jpg);
    background-position: center 30%;
}
#chat .panel-heading, #chat .panel-heading a {
    color:#fff ;
}
#chat .messages{
    box-shadow:none;
    background: #f3f5f9;

}
#chat .messagescontainersent .messages{
    background: #2DADB0;
    border-radius: 8px 8px 0px 8px;
    -webkit-border-radius: 8px 8px 0px 8px;
    color: #fff;
    border-bottom:1px solid #108BB4;
}
#chat .messagescontainersent::after{
    top:0px;
    width: 0;
    height: 0;
    border-top:10px solid transparent;
    border-left: 10px solid #2DADB0;
    border-bottom: 0px solid transparent;

}
#chat .messagescontainer .messages{
    background: #f3f5f9;
    border-radius: 0 8px 8px 8px;
    border-bottom:1px solid #ccc;
    color:black;

}
#chat .messagescontainer::before{
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-right: 10px solid #f3f5f9;
    border-bottom: 10px solid transparent;
}

#chat .msg_container_base{
    background:#fff;
    height:225px;
}
#chat .msg_container_base:hover{
    border:1px double rgb(33, 132, 186);
}
#chat time{ color:#fff; font-style: italic; }

    /**  end of message windo style sheet **/


// textarea.form-control{
// //     max-width:207px;
// // }
.panel-footer{
    background:#0b3277;
        width: 325px;
    margin-left: -10px;


}
            #chathead {
                /*background-image: url(http://www.caixa.gov.br/Style%20Library/images/bg_destaqueInternas.jpg);
                background-position: center 30%;*/
                color: white;
                height: 30px;
                margin-top: 1px;
            }
.fa-2x{
   font-size: 23px;
      }
#draggable { width: 300px;  padding: 0; display: none;}

.chat {

    width: 300px;
    margin: 0 auto;
    position:fixed;
}

    .chat header {

      /*background-image: url(http://www.caixa.gov.br/Style%20Library/images/bg_destaqueInternas.jpg);
   background-position: -196% !important;*/
         /* background-color: #0079e4;*/
         background-color: white;
    padding: 10px 15px;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    width: 283px;
    line-height: 15px;
    height: 26px;
    }
    .chat header span{
        font-size: 12px;
    }

        .chat header:before,
        .chat header:after {
            display: block;
            content: '';
            clear: both;
        }

        .chat header h2,
        .chat .body ul li .content h3 {
            margin: 0;
            padding: 0;
            font-size: 14px;
            float: left;
        }

            .chat header h2 a {
                color: #ffffff;
                text-decoration: none;
            }

        .chat header .tools {
            list-style: none;
            margin: 0;
            padding: 0;
            float: right;
        }

            .chat header .tools li {
                display: inline-block;
                margin-right: 6px;
            }

                .chat header .tools li:last-child {
                    margin: 0;
                }

                .chat header .tools li a {
                    color: #ffffff;
                    text-decoration: none;
                    -webkit-transition: all 0.3s linear 0s;
                    -moz-transition: all 0.3s linear 0s;
                    -ms-transition: all 0.3s linear 0s;
                    -o-transition: all 0.3s linear 0s;
                    transition: all 0.3s linear 0s;
                }

    .chat .body {
        /*background-image:url("../images/chtback.jpg");*/
        /*background-color:white;*/
        background-color:white;
        max-height: 360px;
        overflow-y: scroll;
         height: 315px;

    }

        /*.chat .body .search {
            display: none;
            width: 100%;
        }*/

            /*.chat .body .search.opened {
                display: block;
            }*/

            /*.chat .body .search input {
                width: 100%;
                margin: 0;
                padding: 10px 15px;
                border: none;
                -webkti-box-size: border-box;
                -moz-box-size: border-box;
                box-size: border-box;
            }*/

        .chat .body ul {
            list-style: none;
            padding: 0;
            margin: 0;
            border-top: 1px solid #f2f2f2;
        }

            .chat .body ul li {
                position: relative;
                background: #ffffff;
                display: block;
                width: 100%;
                padding: 10px;
                box-sizing: border-box;
                /*opacity:0.9;*/
                height:50px;
            }

                .chat .body ul li:before,
                .chat .body ul li:after {
                    display: block;
                    content: '';
                    clear: both;
                }

                .chat .body ul li:hover .thumbnail {
                    background: #52bdec;
                    color:white;
                }

                .chat .body ul li:nth-child(2n) {
                    background: #ffe9e9;
                }

                .chat .body ul li .thumbnail {
                       display: inline-block;
                        background: #d46f6a;
                        width: 45px;
                        color: #ffffff;
                        line-height: 38px;
                        text-align: center;
                        text-decoration: none;
                        height: 35px;
                        border-radius: 50%;
                        width: 36px;

                }

                    .chat .body ul li .thumbnail img {
                        width: 100%;
                    }

                .chat .body ul li .content {
                    display: inline-block;
                    margin-left: 6px;
                    vertical-align: top;
                    line-height: 1;
                    width: 70%;
                }

                    .chat .body ul li .content h3 {
                        display: block;
                        width: 100%;
                        margin-bottom: 5px;
                        color: #808080;
                    }

                    .chat .body ul li .content .preview {
                        display: block;
                        width: 100%;
                        max-width: 200px;
                        margin-bottom: 5px;
                        color: chocolate;
                        font-size: 12px;
                        padding-top: 5px;
                    }

                    .chat .body ul li .content .meta {
                        color: #b3b3b3;
                        font-size: 12px;
                    }

                        .chat .body ul li .content .meta a {
                            color: #999999;
                            text-decoration: none;
                        }

                            .chat .body ul li .content .meta a:hover {
                                text-decoration: underline;
                            }

                .chat .body ul li .message {
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    overflow: hidden;
                    height: 100%;
                    width: 100%;
                    padding: 10px;
                    box-sizing: border-box;
                }

    .chat footer a {
       /*background-image: url(http://www.caixa.gov.br/Style%20Library/images/bg_destaqueInternas.jpg);
   background-position: -196% !important;*/
       background-color:#0079e4;
        display: block;
        width: 100%;
        padding: 10px 15px;
        color: #ffffff;
        font-size: 8px;
        text-align: center;
        text-decoration: none;
        box-sizing: border-box;
            height: 20px;
                line-height: 6px;
    }

        /*.chat footer a:hover {
            background: #cd8ca0;
            -webkit-transition: background 0.3s linear 0s;
            -moz-transition: background 0.3s linear 0s;
            -ms-transition: background 0.3s linear 0s;
            -o-transition: background 0.3s linear 0s;
            transition: background 0.3s linear 0s;
        }*/

/*.info {
    width: 300px;
    margin: 25px auto;
    text-align: center;
}

    .info h1 {
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
    }

    .info span {
        color: #666666;
        font-size: 12px;
    }

        .info span a {
            color: #000000;
            text-decoration: none;
        }

        .info span .fa {
            color: #bd6982;
        }

        .info span .spoilers {
            color: #999999;
            margin-top: 5px;
            font-size: 10px;
        }*/

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
}

/*.glyphicon-minus:before {
    content: "\2212";
}

.glyphicon-plus:before {
    content: "\2b";
}*/


/**, *:before, *:after {
    box-sizing: border-box;
}*/



.panel-heading {
    background-color: blueviolet;
    background-position: center 30%;
}

.top-bar {
    background: #666;
    color: white;
    padding: 10px;
    position: relative;
    overflow: hidden;
}

.icon_minim {
    padding: 2px 10px;
}

.panel {
    margin-bottom: 0px;
}

.panel-heading {
    /*background-image: url(http://www.caixa.gov.br/Style%20Library/images/bg_destaqueInternas.jpg);
    background-position: center 30%;*/
}

.panel-heading, #chat .panel-heading a {
    color: #fff;
}

.msg_container_base {
    background: #e5e5e5;
    margin: 0;
    padding: 0 10px 10px;
    max-height: 300px;
    overflow-x: hidden;
}

.show_hide {
    display: none;
}
#btn-chat{
    background-color:darkcyan;
}
.messagescontainer{
    padding-right: 75px;
    padding-bottom: 15px;
}
.messagescontainersent{
        padding-left: 75px;
        padding-bottom: 15px;
}
#msgbodycontainer{
    /*background-image:url("../images/chtback.jpg");*/
    background-color:white;
        border: none;
            max-height: 255px;
}

.datetimedisp{
        font-size: 9px;
        float: right;
        color: #dcdfe4c7;
}

.buttonsend{
      float: right;
    padding: 15px 14px 15px 7px;
       margin-right: 29px;
    border-radius: 0px 25px 25px 0px;
    background-color: #73c726;
    color: ghostwhite;
    font-size: 18px;
    border: none;
        color: white;
    text-shadow:  -2px 1px 3px black;
        /*box-shadow: 0px 2px 2px 1px #4b9824;*/
        cursor:pointer;

}
.unreadmsg{
    float: right;
    /* margin-bottom: -28px; */
    margin-top: -32px;
    padding: 4px 6px 4px 6px;
    background-color: #41b8ec;
    color: #ffebeb;
    border-radius: 50%;
    font-size: 14px;
}
